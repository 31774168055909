import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from "react-bootstrap";
import { FaDonate, FaBitcoin, FaEthereum, FaCoins } from 'react-icons/fa'; 
import navIcon1 from "../assets/img/nav-icon1.svg";
import navIcon2 from "../assets/img/nav-icon2.svg";
import navIcon3 from "../assets/img/nav-icon3.svg";
import navIcon4 from '../assets/img/nav-icon4.svg';
import navIcon5 from '../assets/img/nav-icon5.svg';
import solana from '../assets/img/solana.png';
import navIcon6 from '../assets/img/nav-icon6.svg';


export const Footer = () => {
  const [isGlowing, setIsGlowing] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setIsGlowing(prev => !prev);
    }, 1000); // Cambia cada 1 segundo

    return () => clearInterval(interval);
  }, []);

  return (
    <footer className="footer">
    <Container>
      <Row className="align-items-center">
        {/* Columna 1: Apoyar el proyecto */}
        <Col size={12} md={4} className="text-center">
          <div className="donate-wrapper"> 
            <a href="https://link.mercadopago.com.ar/colaboracionalcista" target="_blank" rel="noopener noreferrer" className={`donate-button ${isGlowing ? 'glowing' : ''}`}>
              <FaDonate className="donate-icon" /> ¡Apoya el proyecto!
            </a>
          </div>
        </Col>

        {/* Columna 2: Criptomonedas */}
        <Col size={12} md={4} className="crypto-info text-center">
        
  <h3>Colaboraciones</h3>
  <p><FaBitcoin /> BITCOIN:</p>
  <p>1NahXGbAmsh3t51hrcRxVsmGEuedVPjX6d</p>
  <hr /> {/* Divisor horizontal */}
  <div>
      <p>
        <img src={solana} alt="Solana" style={{ width: '24px', height: '24px', marginRight: '8px' }} /> SOLANA:
      </p>
      <p>5wPR3C5pzo1mwecAk3oyvE2sGB4Q8s1PoRiHnsJRqsXS</p>
    </div>
  <hr /> {/* Divisor horizontal */}
  <p><FaCoins /> USDT:</p>
  <p>
    BEP20: 0xf096bfe6e03071f26215f552a462edff190a4e2a<br />
    TRC20: TSFUehSQjGhMqyJEjXDqKLFPhXJuK9Apm5
  </p>
</Col>

        {/* Columna 3: Iconos de redes sociales */}
        <Col size={12} md={4} className="text-center text-sm-end">
          <div className="social-icon">
            <a href="https://www.youtube.com/@EnTendenciaAlcista" target="_blank"><img src={navIcon1} alt="Icon" /></a>
            <a href="https://api.whatsapp.com/send/?phone=%2B5491123311350&text=Hola+quiero+contactarme+contigo&type=phone_number&app_absent=0" target="_blank"><img src={navIcon6} alt="Icon" /></a>
            <a href="https://www.instagram.com/en.tendencia.alcista/" target="_blank"><img src={navIcon3} alt="Icon" /></a>
            <a href="https://www.threads.net/@en.tendencia.alcista" target="_blank"><img src={navIcon4} alt="Icon" /></a>
            <a href="https://www.tiktok.com/@en.tendencia.alcista" target="_blank"><img src={navIcon5} alt="Icon" /></a>
            <a href="https://www.facebook.com/enTendenciaAlcista?locale=es_LA" target="_blank"><img src={navIcon2} alt="Icon" /></a>
          </div>
          <h4>VAMOS QUE SE PUEDE!!!</h4>
        </Col>
      </Row>
    </Container>
  </footer>
  );
};
