import React, { useState } from "react";
import { Container, Row, Col, Modal, Button } from "react-bootstrap";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import meter1 from "../assets/img/servicios.png";
import meter2 from "../assets/img/servicios.png";
import meter3 from "../assets/img/servicios.png";
import { FaAngleDoubleLeft, FaAngleDoubleRight } from "react-icons/fa";

export const Skills = () => {

  // Función para formatear la descripción con saltos de línea y enlaces
  const formatDescription = (text) => {
    return text.split('\n').map((line, index) => {
      if (line.includes('LINK:')) {
        const link = line.split('LINK: ')[1];
        return (
          <React.Fragment key={index}>
            <a
              href={link}
              target="_blank"
              rel="noopener noreferrer"
            >
              {link}
            </a>
            <br />
          </React.Fragment>
        );
      } else {
        return (
          <React.Fragment key={index}>
            {line}<br />
          </React.Fragment>
        );
      }
    });
  };

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState({
    title: "",
    description: ""
  });

  const handleImageClick = (title, description) => {
    setModalContent({ title, description });
    setShowModal(true);
  };

  return (
    <section className="skill" id="skills">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="skill-bx wow zoomIn">
              <h2>Servicios</h2>
              <p>
              </p>
              <Carousel
                 responsive={responsive}
                  infinite={true}
                  className="owl-carousel owl-theme skill-slider"
                  customLeftArrow={
                    <button className="carousel-arrow carousel-arrow-left">
                      <FaAngleDoubleLeft />
                    </button>
                  }
                  customRightArrow={
                    <button className="carousel-arrow carousel-arrow-right">
                      <FaAngleDoubleRight />
                    </button>
                  }
              >
                <div className="item" onClick={() => handleImageClick(
                  "Vamos a poder hablar de lo que necesites para ayudarte a cumplir tus objetivos, como por ejemplo:",
                  "• Finanzas Personales\n• Cripto\n• Inversiones\n• Emprendimientos\n• Trading o el tema que necesites que te guíe y ayude.\n\nEs online, de 1 hora, y se hace por Meet (es como Zoom pero de Google).\n\nPodés elegir entre: pack 1 (1 sesión), pack 3 (3 sesiones), pack 5 (5 sesiones)"
                )}>
                  <img src={meter1} alt="Personalizados" />
                  <h5>Personalizados</h5>
                </div>
                <div className="item" onClick={() => handleImageClick(
                  "Este servicio está orientado a personas que:",
                  "• Están aprendiendo sobre la Bolsa de Valores\n• Haciendo sus primeras inversiones\n• Inversores experimentados que quieran o necesiten tener un seguimiento.\n\nEs una membresía TRIMESTRAL en donde accederán a los siguientes beneficios:\n• Contacto directo por WhatsApp\n• Seguimiento en BullMarket Brokers\n• Asesoramiento personalizado\n• Armado de cartera\n• Planificación financiera\n• Encuentros en vivo\n• Acceso a la Comunidad Privada por WhatsApp\n• Información exclusiva para mejorar la toma de decisiones en tus estrategias de inversión"
                )}>
                  <img src={meter3} alt="Membresía de asesoramiento" />
                  <h5>Membresía de asesoramiento</h5>
                </div>
                <div className="item" onClick={() => handleImageClick(
                  "Consta de 5 encuentros en vivo (1 por semana), online e interactivo, en donde te voy a enseñar y brindar herramientas para que puedas tomar el control de tus finanzas.",
                  "Los temas y herramientas que voy a brindarte son:\n• Mindset\n• Fijar objetivos\n• Uso efectivo de nuestros recursos\n• 2 modelos de presupuesto\n• Cómo hacer las matemáticas\n• Ser estratega con la deuda"
                )}>
                  <img src={meter3} alt="Image" />
                  <h5>Seminario finanzas personales</h5>
                </div>
                <div className="item" onClick={() => handleImageClick(
                  "El Profe Boico (Christian) y En Tendencia Alcista (Sacha) les traen un Newsletter con información esencial para tomar decisiones de inversión inteligentes.",
                  "¿Qué recibirán?\n•Análisis de la coyuntura económica nacional e internacional.\n• Datos sobre Renta Fija y Renta Variable.\n• Consejos prácticos para invertir con menor riesgo.\n•Datos sobre Criptomonedas.\n• Con conocimiento e información de alto Valor, sus inversiones pueden crecer hasta el infinito y mas allá.\n•Formulario: LINK: https://forms.gle/kChcPoHsJmHG7g4y5"
                )}>
                  <img src={meter3} alt="Image" />
                  <h5>Newsletter 3.0</h5>
                </div>
              </Carousel>
            </div>
          </div>
        </div>

        {/* Modal de Bootstrap */}
        <Modal show={showModal} onHide={() => setShowModal(false)} centered backdrop="static" dialogClassName="transparent-modal" scrollable>
          {modalContent && (
            <Modal.Dialog className="modal-dialog-scrollable">
              <>
                <Modal.Body>
                  <h3>{modalContent.title}</h3>
                  <p>{formatDescription(modalContent.description)}</p>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={() => setShowModal(false)}>
                    Cerrar
                  </Button>
                </Modal.Footer>
              </>
            </Modal.Dialog>
          )}
        </Modal>
      </div>
    </section>
  );
};
