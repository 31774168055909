import React from "react";
import { Col } from "react-bootstrap";

export const ProjectCard = ({ title, description, imageUrl, youtubeLink }) => {
  const handleYoutubeClick = () => {
    window.open(youtubeLink, "_blank");
  };

  return (
    <Col size={12} sm={6} md={4}>
      <div className="proj-imgbx" onClick={handleYoutubeClick}>
        <img src={imageUrl} alt={title} />
        <div className="proj-txtx">
          <h4>{title}</h4>
          <span>{description}</span>
        </div>
      </div>
    </Col>
  );
};
