import { Container, Row, Col } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";

export const Projects = () => {
  const projects = [
    {
      title: "Mi experiencia en Crowdfunding",
      description: " | ¿Qué es? | ¿Es rentable? | ¿Dónde lo hago? |",
      imageUrl:
        "https://i.ytimg.com/vi/Wzu1BhECw-c/hqdefault.jpg?sqp=-oaymwEcCPYBEIoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLBRT8hVRrAxEBg7ce23a93XXeLvRA",
      youtubeLink: "https://www.youtube.com/watch?v=Wzu1BhECw-c",
    },
    {
      title: "Los 4 pilares",
      description: "Trading de Manera Rentable en el Tiempo",
      imageUrl:
        "https://i.ytimg.com/vi/62I5AS7bPJU/hqdefault.jpg?sqp=-oaymwEcCPYBEIoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLDF_N4wgXOtgPRLx30lEcogPnk5ZA",
      youtubeLink: "https://www.youtube.com/watch?v=62I5AS7bPJU", // Enlace corregido
    },
    {
      title: "¿Cómo transferir Criptomonedas?",
      description: "Transfiriendo 1 SOL entre Exchange",
      imageUrl:
        "https://i.ytimg.com/vi/9-_fktofeOQ/hqdefault.jpg?sqp=-oaymwEcCPYBEIoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLAysIU2hW8iNvOkoCGfrZA17NPJhQ",
      youtubeLink: "https://www.youtube.com/watch?v=9-_fktofeOQ", // Enlace corregido
    },
  ];

  return (
    <section className="project" id="projects">
      <Container>
        <Row>
          <Col size={12}>
            <h2 className="mb-3">Experencia Alcista</h2>
            <div className="soysacha">
              <h2 className="soysacha-heading gradient-text">
                Soy Sacha y estos son algunos de mis videos
              </h2>
            </div>

            {/* <p>Explora los proyectos en los que he trabajado y descubre mi enfoque creativo y habilidades técnicas en acción.</p> */}
            <Row>
              {projects.map((project, index) => (
                <ProjectCard
                  key={index}
                  title={project.title}
                  description={project.description}
                  imageUrl={project.imageUrl}
                  youtubeLink={project.youtubeLink}
                />
              ))}
            </Row>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
