import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import headerImg from "../assets/img/lobo1.png";
import { ArrowRightCircle, Justify } from 'react-bootstrap-icons';
import 'animate.css';
import TrackVisibility from 'react-on-screen';

export const Banner = () => {
  const [index, setIndex] = useState(0);
  const [text, setText] = useState('');
  const toRotate = [""];
  const period = 500;

  useEffect(() => {
    const ticker = setInterval(() => {
      tick();
    }, period);

    return () => clearInterval(ticker);
  }, [index]);

  const tick = () => {
    const fullText = toRotate[index % toRotate.length];
    setText(prevText => {
      if (prevText === fullText) {
        return '';
      } else {
        return fullText.substring(0, prevText.length + 1);
      }
    });
  };

  return (
    <section className="banner" id="home">
      <Container>
        <Row className="aligh-items-center">
          <Col xs={12} md={6} xl={7}>
            <TrackVisibility>
              {({ isVisible }) => (
                <div className={isVisible ? "animate__animated animate__fadeIn" : ""}>
                  <span className="tagline">EN TENDENCIA ALCISTA</span>
                  <h2>BIENVENIDOS A LA COMUNIDAD MÁS ALCISTA DE TODA LATINOAMÉRICA</h2>
                  <h3><strong>VAMOS QUE SE PUEDE!!!</strong> </h3>
                </div>
              )}
            </TrackVisibility>
          </Col>
          <Col xs={12} md={6} xl={5}>
            <TrackVisibility>
              {({ isVisible }) => (
                <div className={isVisible ? "animate__animated animate__zoomIn" : ""}>
                  <img src={headerImg} alt="Header Img"/>
                </div>
              )}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
