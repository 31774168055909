import React, { useState, useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import { Container, Row, Col, Modal, Button } from "react-bootstrap";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import meter0 from "../assets/img/Beneficios.png";
import meter1 from "../assets/img/logo_pigmaleon.png";
import meter2 from "../assets/img/electrodomesticos.png";
import meter3 from "../assets/img/nano.png";
import meter4 from "../assets/img/bitget.png";
import meter5 from "../assets/img/profeboico.png";
import meter8 from "../assets/img/suplementos.png";
import meter9 from "../assets/img/taller.png";
import meter10 from "../assets/img/asesoramiento.jpeg";
import meter11 from "../assets/img/alvi.png";
import meter12 from "../assets/img/ugc.png";


import { FaAngleDoubleLeft, FaAngleDoubleRight } from "react-icons/fa";

import Confetti from "react-confetti";
import {
  FaWhatsapp,
  FaExternalLinkAlt,
  FaEnvelope,
  FaInstagram,
} from "react-icons/fa"; // Iconos de React Icons

export const Benefits = () => {
  // Función para formatear la descripción con saltos de línea
  const formatDescription = (text) => {
    const lines = text.split("\n");
    return lines.map((line, index) => {
      if (line.includes("Contacto:")) {
        // Si la línea contiene "Contacto:"
        return (
          <React.Fragment key={index}>
            <a
              href="https://api.whatsapp.com/send/?phone=%2B5491130192910&text=Hola+quiero+contactarme+contigo&type=phone_number&app_absent=0"
              target="_blank"
              rel="noopener noreferrer"
            >
              Contacto por WhatsApp
            </a>
            <br />
          </React.Fragment>
        );
      } else {
        return (
          <React.Fragment key={index}>
            {line}
            <br />
          </React.Fragment>
        );
      }
    });
  };

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState(null); // Inicialmente null
  const [confettiPositions, setConfettiPositions] = useState([]);
  const benefitsRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting && confettiPositions.length === 0) {
          // No activar el modal al entrar en la sección
        }
      },
      {
        threshold: 0.5, // Activar cuando el 50% del componente sea visible
      }
    );

    if (benefitsRef.current) {
      observer.observe(benefitsRef.current);
    }

    return () => {
      if (benefitsRef.current) {
        observer.unobserve(benefitsRef.current);
      }
    };
  }, []); // No se necesita dependencia de confettiPositions

  const handleImageClick = (
    title,
    description,
    whatsappLink,
    link,
    email,
    instagram,
    event
  ) => {
    setModalContent({
      title,
      description,
      whatsappLink,
      link,
      email,
      instagram,
    }); // Actualizar el contenido del modal
    setShowModal(true); // Mostrar el modal

    // Calcular la posición del clic en relación a la imagen
    const imageRect = event.target.getBoundingClientRect();
    const x = imageRect.left + imageRect.width / 2;
    const y = imageRect.top + imageRect.height / 2 + window.scrollY;

    setConfettiPositions((prevPositions) => [...prevPositions, { x, y }]);
  };

  return (
    <>
      <section className="Benefits" id="Benefits" ref={benefitsRef}>
        <Container>
          <Row>
            <Col>
              <div className="skill-bx wow zoomIn mt-4">
                <h1>Beneficios</h1>
                <br></br>
                <Carousel
                  responsive={responsive}
                  infinite={true}
                  className="owl-carousel owl-theme skill-slider"
                  customLeftArrow={
                    <button className="carousel-arrow carousel-arrow-left">
                      <FaAngleDoubleLeft />
                    </button>
                  }
                  customRightArrow={
                    <button className="carousel-arrow carousel-arrow-right">
                      <FaAngleDoubleRight />
                    </button>
                  }
                >
                  <div
                    className="item"
                    onClick={(e) =>
                      handleImageClick(
                        "Con miedo SIEMPRE, desde el miedo NUNCA",
                        "Código Alcista 10% descuento en personalizados y seminario de la Introducción a la PNL.\n",
                        "https://api.whatsapp.com/send/?phone=%2B5491130192910&text=Hola+quiero+contactarme+contigo&type=phone_number&app_absent=0",
                        "https://www.pigmalionpd.com",
                        null,
                        null,

                        e
                      )
                    }
                  >
                    <img src={meter1} alt="Image" className="mt-5 benefit-image" />
                    <h5>Pigmalion - Proceso Evolutivo</h5>
                  </div>

                  <div
                    className="item"
                    onClick={(e) =>
                      handleImageClick(
                        "Electrodomésticos Alcistas",
                        "Código Alcista 20% descuento",
                        "https://api.whatsapp.com/send/?phone=%2B5491123905219&text=Hola+quiero+contactarme+contigo&type=phone_number&app_absent=0",
                        null,
                        null,
                        null,
                        e
                      )
                    }
                  >
                    <img src={meter2} alt="Image" className="mt-5 benefit-image" />
                    <h5>Can electrodomésticos</h5>
                  </div>

                  <div
                    className="item"
                    onClick={(e) =>
                      handleImageClick(
                        "Soluciones informáticas de nivel Alcista(páginas web, soporte técnico)",
                        "Código Alcista 20% descuento",
                        null,
                        null,
                        "abelvincenti@nano-and-co.com",
                        null,
                        e
                      )
                    }
                  >
                    <img src={meter3} alt="Image" className="mt-5 benefit-image" />
                    <h5>Nano & Co - Soluciones informáticas</h5>
                  </div>

                  <div
                    className="item"
                    onClick={(e) =>
                      handleImageClick(
                        "Descuentos en comisiones spot y soporte exclusivo exchange",
                        "",
                        null,
                        "https://partner.bitget.com/bg/X44KL4",
                        null,
                        null,
                        e
                      )
                    }
                  >
                    <img src={meter4} alt="Image" className="mt-5 benefit-image" />
                    <h5>Bitget Partner</h5>
                  </div>

                  <div
                    className="item"
                    onClick={(e) =>
                      handleImageClick(
                        "Experto en análisis técnico",
                        "Código Alcista 10% descuento en personalizados, cursos y seminarios.",
                        null,
                        null,
                        null,
                        "https://www.instagram.com/profeboico/",
                        e
                      )
                    }
                  >
                    <img src={meter5} alt="Image" className="mt-5 benefit-image" />
                    <h5>Profe Boico</h5>
                  </div>

                  <div
                    className="item"
                    onClick={(e) =>
                      handleImageClick(
                        "Daniel Vidal",
                        "Código Alcista 20% descuento",
                        "https://api.whatsapp.com/send/?phone=%2B5491157585864&text=Hola+quiero+contactarme+contigo&type=phone_number&app_absent=0",
                        null,
                        null,
                        null,
                        e
                      )
                    }
                  >
                    <img src={meter10} alt="Image" className="mt-5 benefit-image" />
                    <h5>Asesoramiento Contable Fiscal</h5>
                  </div>

                  <div
                    className="item"
                    onClick={(e) =>
                      handleImageClick(
                        "Taller de cambio nutricional",
                        "Código Alcista 20% descuento.",
                        "https://api.whatsapp.com/send/?phone=%2B5491146730821&text=Hola+quiero+contactarme+contigo&type=phone_number&app_absent=0",
                        null,
                        null,
                        null,
                        e
                      )
                    }
                  >
                    <img src={meter9} alt="Image" className="mt-5 benefit-image" />
                    <h5>Taller de cambio</h5>
                  </div>

                  <div
                    className="item"
                    onClick={(e) =>
                      handleImageClick(
                        "En tu próxima compra",
                        "Código Alcista 10% descuento.",
                        null,
                        "https://www.suplementosfitness.com.ar",
                        null,
                        null,
                        e
                      )
                    }
                  >
                    <img src={meter8} alt="Image" className="mt-5 benefit-image" />
                    <h5>Suplemento fitness</h5>
                  </div>

                  <div
                    className="item"
                    onClick={(e) =>
                      handleImageClick(
                        "Gestión de anuncios en redes sociales para tu negocio",
                        "Código Alcista 10% descuento.",
                        null,
                        null,
                        null,
                        "https://www.instagram.com/mktcon.alvi?igsh=djhvajdpcWo5MHN6",
                        e
                      )
                    }
                  >
                    <img src={meter11} alt="Image" className="benefit-image" />
                    <h5>Marketing con Alvi</h5>
                  </div>

                  <div
                    className="item"
                    onClick={(e) =>
                      handleImageClick(
                        "Si sos emprendedor, tenes un negocio, vendes productos/servicios puedo ayudarte a conectar con tus clientes generando Contenido UGC.",
                        "Código ALCISTA vas a obtener un 20% de descuento en mis servicios. De regalo una asesoría de 30 minutos",
                        "https://api.whatsapp.com/send/?phone=%2B5491160474211&text=Hola+quiero+contactarme+contigo&type=phone_number&app_absent=0",
                        null,
                        null,
                        null,
                        e
                      )
                      
                    }
                    
                  >
                    <img src={meter12} alt="Image" className="mt-5 benefit-image" />
                    <h5>Creación de Contenido UGC</h5>
                  </div>
                </Carousel>
              </div>
            </Col>
          </Row>
        </Container>

        {/* Modal de Bootstrap */}
        {/* Modal de Bootstrap modificado */}
        <Modal
          show={showModal}
          onHide={() => setShowModal(false)}
          centered
          backdrop="static"
          dialogClassName="transparent-modal"
        >
          {modalContent && (
            <>
              <Modal.Body>
                <h3>{modalContent.title}</h3>
                <p>{formatDescription(modalContent.description)}</p>

                {modalContent.whatsappLink && (
                  <a
                    href={modalContent.whatsappLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="whatsapp-link"
                  >
                    <FaWhatsapp
                      style={{ marginRight: "8px", color: "#25D366" }}
                    />{" "}
                    Contacto por WhatsApp
                  </a>
                )}
                {modalContent.link && (
                  <a
                    href={modalContent.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="website-link"
                  >
                    <FaExternalLinkAlt
                      style={{ marginRight: "8px", color: "#007bff" }}
                    />{" "}
                    Visitanos
                  </a>
                )}

                {modalContent.email && (
                  <a
                    href={`mailto:${modalContent.email}`}
                    className="email-link"
                  >
                    <FaEnvelope
                      style={{ marginRight: "8px", color: "#007bff" }}
                    />{" "}
                    Enviar Correo
                  </a>
                )}

                {modalContent.instagram && (
                  <a
                    href={modalContent.instagram}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="instagram-link"
                  >
                    <FaInstagram
                      style={{ marginRight: "8px", color: "#E4405F" }}
                    />{" "}
                    Instagram
                  </a>
                )}
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={() => setShowModal(false)}>
                  Cerrar
                </Button>
              </Modal.Footer>
            </>
          )}
        </Modal>
      </section>

      {/* Contenedor para el confeti */}
      {ReactDOM.createPortal(
        <div id="confetti-container">
          {confettiPositions.map((pos, i) => (
            <Confetti
              key={i}
              recycle={false}
              onConfettiComplete={() =>
                setConfettiPositions((prevPositions) =>
                  prevPositions.filter((_, index) => index !== i)
                )
              }
              initialPosition={{ x: pos.x, y: pos.y }}
            />
          ))}
        </div>,
        document.body
      )}
    </>
  );
};
