import React, { useState, useRef } from "react";
import { Container, Row, Col } from "react-bootstrap";
import contactImg from "../assets/img/cuete.png";
import StarsCanvas from "../components/canvas/Stars";
import 'animate.css';
import TrackVisibility from 'react-on-screen';
import emailjs from "@emailjs/browser";
import Swal from 'sweetalert2';


export const Contact = () => {
  const form = useRef();
  const [formData, setFormData] = useState({
    user_name: '',
    user_email: '',
    user_phone: '',
    subject: '',
    message: ''
  });
  const [buttonText, setButtonText] = useState("Enviar");
  const [status, setStatus] = useState({});

  const onFormUpdate = (category, value) => {
    setFormData({
      ...formData,
      [category]: value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setButtonText("Enviando...");

    emailjs.sendForm(
      'service_gcrqtev',
      'template_tk30ye8',
      form.current,
      'KY2KhpEeXmP8GgM1K'
    )
    .then(
      (result) => {
        console.log(result.text);
        Swal.fire({
          icon: 'success',
          title: 'Mensaje enviado!',
          text: 'Gracias por contactarme. Te responderé pronto.',
          customClass: {
            popup: 'my-custom-popup',
          }
        });
        setFormData({ user_name: '', user_email: '', user_phone: '', subject: '', message: '' });
      },
      (error) => {
        console.log(error.text);
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Algo salió mal. Por favor, intenta de nuevo.'
        });
      }
    )
    .finally(() => {
      setButtonText("Enviar");
    });
  };

  return (
    <section className="contact" id="connect">
      <div className="stars-canvas-container">
      <StarsCanvas className="stars-canvas" />
      </div>
      <Container className="content-container">
        <Row className="align-items-center">
          <Col size={12} md={6}>
            <TrackVisibility>
              {({ isVisible }) => (
                <div>
                  <img className={isVisible ? "animate__animated animate__zoomIn contactImg" : "contactImg"} src={contactImg} alt="Contact Us"/>
                </div>
              )}
            </TrackVisibility>
          </Col>
          <Col size={12} md={6}>
            <TrackVisibility>
              {({ isVisible }) => (
                <div className={isVisible ? "animate__animated animate__fadeIn" : ""}>
                  <h2>Contáctame</h2>
                  <form ref={form} onSubmit={handleSubmit}>
                    <Row>
                      <Col size={12} className="px-1">
                        <input type="text" name="subject" value={formData.subject} placeholder="Asunto" onChange={(e) => onFormUpdate('subject', e.target.value)} />
                      </Col>
                      <Col size={12} sm={6} className="px-1">
                        <input type="text" name="user_name" value={formData.user_name} placeholder="Nombre" onChange={(e) => onFormUpdate('user_name', e.target.value)} />
                      </Col>
                      <Col size={12} sm={6} className="px-1">
                        <input type="email" name="user_email" value={formData.user_email} placeholder="Email" onChange={(e) => onFormUpdate('user_email', e.target.value)} />
                      </Col>
                      <Col size={12} sm={6} className="px-1">
                        <input type="tel" name="user_phone" value={formData.user_phone} placeholder="Teléfono" onChange={(e) => onFormUpdate('user_phone', e.target.value)} />
                      </Col>
                      <Col size={12} className="px-1">
                        <textarea rows="6" value={formData.message} placeholder="Mensaje" name="message" onChange={(e) => onFormUpdate('message', e.target.value)}></textarea>
                        <button type="submit" className="submit-button"><span>{buttonText}</span></button>
                        
                      </Col>
                      {
                        status.message &&
                        <Col>
                          <p className={status.success === false ? "danger" : "success"}>{status.message}</p>
                        </Col>
                      }
                    </Row>
                  </form>
                </div>
              )}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Contact;
