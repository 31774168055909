import { useState, useEffect } from "react";
import { Navbar, Nav, Container } from "react-bootstrap";
import logo from '../assets/img/lobo.png';
import navIcon1 from '../assets/img/nav-icon1.svg';
import navIcon2 from '../assets/img/nav-icon2.svg';
import navIcon3 from '../assets/img/nav-icon3.svg';
import navIcon4 from '../assets/img/nav-icon4.svg';
import navIcon5 from '../assets/img/nav-icon5.svg';
import navIcon6 from '../assets/img/nav-icon6.svg';

import {
  BrowserRouter as Router
} from "react-router-dom";

export const NavBar = () => {

  const [activeLink, setActiveLink] = useState('home');
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const onScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    }

    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, [])

  const onUpdateActiveLink = (value) => {
    setActiveLink(value);
  }

  return (
    <Router>
      <Navbar expand="md" className={scrolled ? "scrolled" : ""}>
        <Container>
          <Navbar.Brand href="/">
            <img src={logo} alt="Logo" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav">
            <span className="navbar-toggler-icon"></span>
          </Navbar.Toggle>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto d-flex align-items-center">
              <Nav.Link href="#home" className={activeLink === 'home' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('home')}>Inicio</Nav.Link>
              <Nav.Link href="#skills" className={activeLink === 'skills' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('skills')}>Servicios</Nav.Link>
              <Nav.Link href="#projects" className={activeLink === 'projects' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('projects')}>Experiencia Alcista</Nav.Link>
              <Nav.Link href="#Benefits" className={activeLink === 'Benefits' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('Benefits')}>Beneficios</Nav.Link>
              <div className="social-icon d-flex align-items-center ms-3">
                <a href="https://www.youtube.com/@EnTendenciaAlcista" target="_blank"><img src={navIcon1} alt="Icon" /></a>
                <a href="https://api.whatsapp.com/send/?phone=%2B5491123311350&text=Hola+quiero+contactarme+contigo&type=phone_number&app_absent=0" target="_blank"><img src={navIcon6} alt="Icon" /></a>
                <a href="https://www.instagram.com/en.tendencia.alcista/" target="_blank"><img src={navIcon3} alt="Icon" /></a>
                <a href="https://www.threads.net/@en.tendencia.alcista" target="_blank"><img src={navIcon4} alt="Icon" /></a>
                <a href="https://www.tiktok.com/@en.tendencia.alcista" target="_blank"><img src={navIcon5} alt="Icon" /></a>
                <a href="https://www.facebook.com/enTendenciaAlcista?locale=es_LA" target="_blank"><img src={navIcon2} alt="Icon" /></a>
              </div>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </Router>
  )
}
